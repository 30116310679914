<template>
  <div class="UserAccountDelete" border-top tcenter padding-top padding-right padding-left>
    <p class="title" v-html="$locale['account_delete_confirm']"></p>
    <Input :placeholder="`${$locale['type_here']} ${$locale['delete']}`" autocomplete="false" />
    <Spacer num="2" />
    <Confirm :confirm="confirm" />
    <Spacer num="1" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  computed: {
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
          icon: "times",
          action: () => {
            this.modal.close();
          },
        },
        accept: {
          label: this.$locale["accept"],
          icon: "trash",
          action: () => {
            this.deleteConfirm();
          },
        },
      };
    },
  },
  methods: {
    deleteConfirm: async function() {
      this.keyword = document.querySelector(".UserAccountDelete input").value;

      if (!this.keyword) {
        return this.alert({ message: this.$locale["please_type_keyword"] });
      }

      this.myloader = await this.loader({ message: this.$locale["deleting_account"], showProgress: false });

      try {
        this.req = await this.api.post("user/deleteAccount", { keyword: this.keyword });
        this.deleteSuccess(this.req.data);
      } catch (error) {
        this.alert(Go.getErrorMessage(error));
      }

      this.myloader.close();
    },
    deleteSuccess: function(data) {
      if (!data.success) return this.alert(data);
      this.modal.close(() => {
        if (data.message) this.alert(data);
        this.$store.dispatch("removeUser");
        this.router("/login");
        this.router("/");
      });
    },
  },
};
</script>
